// import React from 'react';
import ReactApexChart from 'react-apexcharts';

const TransactionChart = () => {
  // Sample data for the bar chart
  const options = {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: ['January', 'February', 'March', 'April', 'May'],
    },
  };

  const series = [
    {
      name: 'Revenue',
      data: [30, 40, 25, 50, 49],
    },
  ];

  return (
    <div className="w-full max-w-screen-md mx-auto">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default TransactionChart;
