// import React from 'react'
// import { Outlet } from 'react-router-dom';
import Logo from '../shared/logo/logo';
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AuthLayout = ({ children }) => {
  return (
    <>
    <ToastContainer />
      <div className="w-full h-screen bg-white py-6 md:py-12">
        <div className='grid grid-cols-1 lg:grid-cols-3 mx-auto md:px-20 gap-4'>
          <div className="hidden lg:block" />
          <div className=''>
            <div className='flex justify-center items-center py-5'>
              <Logo />
            </div>
            {/* <Outlet />  */}
           <div className=''>
           {children}
           </div>
          </div>
          <div className="hidden lg:block" />
        </div>


      </div>
    </>
  )
}
AuthLayout.propTypes = {
  children: PropTypes.node
}
export default AuthLayout