import { useState, useEffect, useCallback } from "react";

import Header from "./components/header";
import Product from "./components/product";
import OrderForm from "./forms/OrderForm";
import { productService } from "../../services/guest/product.service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { paymentService } from "../../services/guest/payment.service";
import "react-toastify/dist/ReactToastify.css";
import SubmitButton from "../../components/controls/SubmitButton";
import ClipLoader from "react-spinners/ClipLoader";
// import PropTypes from "prop-types";
import logo from "../../assets/img/logo/enairalogo.png";
import Spinner from "../../components/loader/Spinner";
const Home = () => {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchProductWithPagination = (page) => {
    setLoading(true);
    productService
      .getAll(page)
      .then((result) => {
        if (result && result.data) {
          setProduct(result.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProductWithPagination(1); // Fetch the initial page
  }, []);

  const [collectedData, setCollectedData] = useState({});
  const collectSelectedProducts = useCallback(() => {
    const selectedProducts = product
      .filter((card) => card.checked)
      .map((card) => ({
        productId: card.id,
        quantity: card.totalQuantity,
      }));
    setCollectedData(selectedProducts); // Update the collectedData state
  }, [product]);
  const updateSubPriceAndTotalPrice = (card, quantity) => {
    const pricePerUnit = card.price;
    const newTotalQuantity = card.minimumQuantity * card.count;
    const newSubPrice = newTotalQuantity * pricePerUnit;
    // console.log(`total quantity of each ${newTotalQuantity}`)
    card.perQuantity = quantity;
    card.quantity = newTotalQuantity;
    card.perIndex = card.count;
    card.subPrice = newSubPrice;
    card.totalQuantity = newTotalQuantity;

    // console.log(`total quantity of each ${card.quantity}`)
  };

  const handleQtyChange = (index, e) => {
    const newQuantity = parseInt(e.target.value) || 0;
    const updatedProduct = [...product];
    const card = product[index];

    // Update the card's data
    card.minimumQuantity = newQuantity;

    // Calculate the new subPrice and totalQuantity
    updateSubPriceAndTotalPrice(card, newQuantity, card.count);

    updatedProduct[index] = card;

    setProduct(updatedProduct);
    updateTotalPrices();
    collectSelectedProducts(); // Update total prices when Min Quantity changes
  };

  const handleCountChange = (index, e) => {
    const newIndex = parseInt(e.target.value) || 0;
    const updatedProduct = [...product];
    const card = product[index];

    // Update the card's data
    card.count = newIndex;

    // Calculate the new subPrice and totalQuantity
    updateSubPriceAndTotalPrice(card, card.minimumQuantity, card.count);

    updatedProduct[index] = card;

    setProduct(updatedProduct);
    updateTotalPrices();
    collectSelectedProducts(); // Update total prices when Count changes
  };

  useEffect(() => {
    updateTotalPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTotalPrices = () => {
    // Calculate the total price for all selected cards
    const totalPrice = product.reduce(
      (total, card) => (card.checked ? total + card.subPrice : total),
      0
    );
    setForm({ totalPrice });
  };

  const [form, setForm] = useState({ totalPrice: 0 });

  const handleCheckboxChange = (index) => {
    const updatedProduct = [...product];
    updatedProduct[index] = {
      ...updatedProduct[index],
      checked: !updatedProduct[index].checked,
    };

    // If the checkbox is unchecked, clear count and update totalQuantity and subPrice to 0
    if (!updatedProduct[index].checked) {
      updatedProduct[index].count = 0;
      updatedProduct[index].perIndex = 0;
      updatedProduct[index].totalQuantity = 0;
      updatedProduct[index].subPrice = 0;
    }

    setProduct(updatedProduct);
    updateTotalPrices();
    collectSelectedProducts(); // Update total prices when checkboxes change
  };
  const [successOrder, setSuccessOrder] = useState(false);
  const [orderData, setOrderData] = useState("");
  console.log(JSON.stringify(orderData));
  const [isloading, setIsLoading] = useState(false);

  const ref = orderData.reference;
  const newPayment = () => {
    setIsLoading(true);
    paymentService
      .newPayment(ref)
      .then((response) => {
        if (response.data && response.data.success) {
          window.location.href = response.data.data.paymentUrl;

          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <ToastContainer />

      <div className="w-full h-full bg-light px-6 md:px-0 mx-auto  py-12">
        {!successOrder && (
          <>
            <div className="max-w-[800px] h-full bg-white  mx-auto  rounded-lg shadow-md p-12">
              <Header />
              <Product
                product={product}
                loading={loading}
                form={form}
                handleCheckboxChange={handleCheckboxChange}
                handleQtyChange={handleQtyChange}
                handleCountChange={handleCountChange}
                updateTotalPrices={updateTotalPrices}
              />
              <OrderForm
                SelectedProducts={collectedData}
                successOrder={successOrder}
                setSuccessOrder={setSuccessOrder}
                orderData={orderData}
                setOrderData={setOrderData}
              />
            </div>
          </>
        )}
        {successOrder && (
          <>
            {isloading ? (
              <>
                <Spinner />
              </>
            ) : (
              <>
                <div className="max-w-[800px] h-full bg-white  mx-auto  rounded-lg shadow-md p-12">
                  <div className="flex flex-col space-y-3">
                    <div className="text-center">
                      <p className="capitalize text-xl text-primary font-extrabold">
                        Preview your Order
                      </p>
                      <p className="text-center px-4 md:px-20">
                        Kindly check your order details and delivery information
                        before making payment
                      </p>
                    </div>
                    {/* {loading ? (
          <Spinner />
        ):(
          <> */}
                    <div className="">
                      <p className="text-primary text-lg font-bold">
                        Order Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        {orderData?.orderItems.map((item) => {
                          return (
                            <>
                              <div className="flex flex-col">
                                <div className="flex flex-row space-x-3">
                                  <p className="text-primary text-md">
                                    Product Name:
                                  </p>
                                  <p className="text-dark text-md">
                                    {item?.productName}
                                  </p>
                                </div>
                                <div className="flex flex-row space-x-3">
                                  <p className="text-primary text-md">
                                    Product Price:
                                  </p>
                                  <div className="flex flex-row space-x-1">
                                    <img
                                      src={logo}
                                      className="w-6 h-6"
                                      alt="eNaira-logo"
                                    />
                                    <p className="text-dark text-md">
                                      {typeof item?.price === "number"
                                        ? item?.price.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-row space-x-3">
                                  <p className="text-primary text-md">
                                    Product Quantity:
                                  </p>
                                  <p className="text-dark text-md">
                                    {item?.quantity}
                                  </p>
                                </div>
                                <div className="flex flex-row space-x-3">
                                  <p className="text-primary text-md">
                                    Product Total Price:
                                  </p>
                                  <p className=" flex flex-row space-x-2 text-dark text-md">
                                    <span>
                                      {" "}
                                      <img
                                        src={logo}
                                        className="w-6 h-6"
                                        alt="eNaira-logo"
                                      />
                                    </span>{" "}
                                    <span>{item?.totalPrice}</span>
                                  </p>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between items-center space-x-6 py-4">
                      <div className="flex flex-row space-x-3">
                        <p className="text-lg font-bold">
                          Total Products Quantity:
                        </p>
                        <p className="font-semibold text-lg text-dark">
                          {orderData?.totalQuantity}
                        </p>
                      </div>
                      <div className="flex flex-row space-x-3">
                        <p className="text-lg font-bold">
                          Total Products Price:
                        </p>
                        <div className="flex flex-row space-x-1">
                          <img
                            src={logo}
                            className="w-6 h-6"
                            alt="eNaira-logo"
                          />
                          <p className="font-semibold text-lg text-dark">
                            {typeof orderData.totalPrice === "number"
                              ? orderData.totalPrice.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <p className="text-primary text-md font-bold">
                        Delivery Info
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="flex flex-row space-x-3">
                          <p className="text-primary text-md">First Name:</p>
                          <p className="text-dark text-md">
                            {orderData?.firstname}
                          </p>
                        </div>
                        <div className="flex flex-row space-x-3">
                          <p className="text-primary text-md">Middle Name:</p>
                          <p className="text-dark text-md">
                            {orderData?.middlename}
                          </p>
                        </div>
                        <div className="flex flex-row space-x-3">
                          <p className="text-primary text-md">Last Name:</p>
                          <p className="text-dark text-md">
                            {orderData?.lastname}
                          </p>
                        </div>
                        <div className="flex flex-row space-x-3">
                          <p className="text-primary text-md">Phone No:</p>
                          <p className="text-dark text-md">
                            {orderData?.phone}
                          </p>
                        </div>
                        <div className="flex flex-row space-x-3">
                          <p className="text-primary text-md">Email Address:</p>
                          <p className="text-dark text-md">
                            {orderData?.email}
                          </p>
                        </div>
                        <div className="flex flex-row space-x-3">
                          <p className="text-primary text-md">Wallet Alias:</p>
                          <p className="text-dark text-md">
                            {orderData?.wallet_alias}
                          </p>
                        </div>
                        <div className="flex flex-row space-x-3">
                          <p className="text-primary text-md">Address:</p>
                          <p className="text-dark text-md">
                            {orderData?.address}
                          </p>
                        </div>
                        <div className="flex flex-row space-x-3">
                          <p className="text-primary text-md">LGA:</p>
                          <p className="text-dark text-md">{orderData?.lga}</p>
                        </div>
                        <div className="flex flex-row space-x-3">
                          <p className="text-primary text-md">State:</p>
                          <p className="text-dark text-md">
                            {orderData?.state}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* </>
        )} */}
                    <div>
                      <SubmitButton onClick={!loading ? newPayment : null}>
                        {loading ? (
                          <ClipLoader
                            color="#fff"
                            size={30}
                            data-testid="loader"
                          />
                        ) : (
                          "Make Payment"
                        )}
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Home;
