
function NotFound() {
  return (

    <div className='bg-white'>
       <div className='w-full h-full flex justify-center items-center'>
       <h1 className=' text-5xl font-bold text-primary'>404 Not Found</h1>
       </div>
    </div>
  )
}

export default NotFound