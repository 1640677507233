import Dashboard from "../../views/admin/dashboard";
import Product from "../../views/admin/products";
import Order from "../../views/admin/order";

let routes = [
  {
    path: `/dashboard`,
    component: Dashboard,
    protected: true
  },
  {
    path: `/product`,
    component: Product,
    protected: true
  },
  {
    path: `/order`,
    component: Order,
    protected: true
  },
  
];

export default routes;
