import { util } from "../../constants/api.constant";
import { http } from "../../constants/http";

let productService = {
  
  getAll: async () => {
    return await http.get(`${util.baseUrl}product/all`, {
      headers: {
        "Content-Type": "application/json",
     
      }
    });
  },
 
};

export { productService };

