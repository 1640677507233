import { util } from "../../constants/api.constant";
import { http } from "../../constants/http";

let productService = {
  create: async (body) => {
    const token = localStorage.getItem("adminToken")
    return await http.post(`${util.baseUrl}admin/product/create`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization:`Bearer ${token}`
      }
    });
  },

  uploadPhoto: async (body) => {
    const token = localStorage.getItem("adminToken")
    return await http.post(`${util.baseUrl}admin/product/upload`, body, {
      headers: {
         "Content-Type": "multipart/form-data",
        Authorization:`Bearer ${token}`
      }
    });
  },
  getAll: async (page) => {
    const token = localStorage.getItem("adminToken")
    return await http.get(`${util.baseUrl}admin/product/all`, {
      params:{
        page:page,
        size:util.limit,
        },
      headers: {
        "Content-Type": "application/json",
        Authorization:`Bearer ${token}`
      }
    });
  },
  delete: async (id) => {
    return await http.put(`${util.baseUrl}user/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  },
};

export { productService };

