// import React from 'react';
import { HomeIcon, ShoppingCartIcon, CircleStackIcon, Cog6ToothIcon, WalletIcon} from '@heroicons/react/24/outline';
import Header from './header';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const menuItems = [
    {
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon:<HomeIcon className="text-primary w-6 h-6" />,
    },

    {
        title: 'Order',
        type: 'item',
        url: '/order',
        icon: <ShoppingCartIcon className="text-primary w-6 h-6" />,
    },
    {
        title: 'Product',
        type: 'item',
        url: '/product',
        icon: <CircleStackIcon className="text-primary w-6 h-6" />,
    },
    {
        title: 'Payment',
        type: 'item',
        url: '/payment',
        icon: <WalletIcon className="text-primary w-6 h-6" />,
    },
    {
        title: 'Settings',
        type: 'item',
        url: '/settings',
        icon: <Cog6ToothIcon className="text-primary w-6 h-6" />,
    },
];

const Sidebar = ({ isSidebarOpen }) => {
    return (
        <div className={`w-60 md:w-80 fixed bg-gray-50 h-screen shadow-sm ${isSidebarOpen ? "block" : "hidden"}`}>
          <Header />
            <div className="py-6 px-2">
                <ul className="flex flex-col space-y-6 px-8">
                    {menuItems.map((lis, index) => {
                        return (
                            <li key={index} >
                                <Link to={lis.url} className="flex flex-row items-center space-x-2">
                                <span className="text-primary text-xl">{lis.icon}</span>
                                <span className="text-lg font-regular">{lis.title}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
Sidebar.propTypes = {
    isSidebarOpen: PropTypes.bool,
}
export default Sidebar;
