import { useState } from 'react';
import Input from "../../../../components/controls/Input"
import PhotoUpload from "../../../../components/controls/PhotoUpload"
import AmountField from './../../../../components/controls/AmountField';
import SubmitButton from './../../../../components/controls/SubmitButton';
import { productService } from '../../../../services/admin/product.service';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types"
import ClipLoader from "react-spinners/ClipLoader";


const AddProduct = (props) => {
  const { onClose, fetchProductWithPagination } = props;
  const [form, setForm] = useState({
    name: "",
    description: "",
    minimumQuantity: 0,
    quantity: 0,
    price: 0,
    thumbnail: ""
  });

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [image, setImage] = useState([]);

  const handleAmountChange = (amount) => {
    setAmount(amount);
    setForm({ ...form, price: amount }); // Update the 'price' in the form state
  };
  const createProduct = async() => {
    if (form.name !== "" && form.description !== "" && form.minimumQuantity !== "" && form.quantity !== "" && amount !== 0) {
      if (image) {
        setLoading(true);
        const files = new FormData();
        files.append("file", image);

       await  productService.uploadPhoto(files).then(async(imageResult) => {
          if (imageResult && imageResult.data.success) {
            toast.success(imageResult.data.message);
            setLoading(true);

            const req = {
              name: form?.name,
              description: form?.description,
              minimumQuantity: form?.minimumQuantity,
              quantity: form?.quantity,
              price: form?.price,
              thumbnail: imageResult.data.data.url,
            }
            // console.log(JSON.stringify(req))
        setTimeout(async ()=>{
          await  productService.create(req).then((result) => {
            if (result && result?.data?.data && result.data.success) {
              toast.success(result?.data?.message);
              setTimeout(() => {
                onClose();
                fetchProductWithPagination(1)
              }, 4000);
            } else {
              toast(result.data?.message);
            }
          }).catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message);
            console.log(error);
          }).finally(() => {
            setLoading(false);
          });
        }, 2000)
          } else {
            toast.error(imageResult?.data?.message);
          }
        }).catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
          console.log(error);
        }).finally(() => {
          setLoading(false);
        });
      } else {
        toast.error("No image selected.");
      }
    } else {
      toast.error("All fields are required!!");
    }
  };

  return (
    <div className="p-5">
      <div className="text-center">
        <p className="text-primary font-bold text-xl">Add Product</p>
        <p className='text-dark text-md'>First upload the image and then the product details...</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-1 md:col-span-2 py-4">
          <div className='flex flex-row justify-center items-center space-x-4'>
            <PhotoUpload
              onImageSelect={(e) => {
                const files = e.target.files;
                console.log(files[0])
                if (files.length > 0) {
                  // Store only the first selected file
                  setImage(files[0]);

                } else {
                  setImage(null);
                }
              }}
              name="thumbnail"
            />
          </div>
        </div>
        <div>
          <Input
            type="text"
            name="name"
            label="Name"
            placeholder="Name"
            onChange={(e) => {
              setForm({ ...form, name: e.target.value });
            }}
            value={form.name}
          />
        </div>
        <div>
          <Input
            type="text"
            name="description"
            label="Description"
            placeholder="Description"
            onChange={(e) => {
              setForm({ ...form, description: e.target.value });
            }}
            value={form.description}
          />
        </div>
        <div>
          <Input
            type="text"
            name="minimumQuantity"
            label="Minimum Quantity"
            placeholder="Minimum Quantity"
            onChange={(e) => {
              setForm({ ...form, minimumQuantity: e.target.value });
            }}
            value={form.minimumQuantity}
          />
        </div>
        <div>
          <Input
            type="text"
            name="quantity"
            label="Quantity"
            placeholder="Quantity"
            onChange={(e) => {
              setForm({ ...form, quantity: e.target.value });
            }}
            value={form.quantity}
          />
        </div>
        <div>
          <AmountField
            name="price"
            label="Price"
            placeholder="Price"
            value={amount}
            onChange={handleAmountChange}
          />
        </div>
      </div>
      <div className='py-4'>
        <SubmitButton
          onClick={!loading ? createProduct : null}
        >
          {loading ? (
            <ClipLoader
              color="#fff"
              size={30}
              data-testid="loader"
            />
          ) : (
            "Create"
          )}
        </SubmitButton>
      </div>
    </div>
  );
};

AddProduct.propTypes = {
  onClose: PropTypes.func,
};

export default AddProduct;
