import React, { useState } from 'react';
import Navbar from '../views/admin/components/navbar/navbar';
import Sidebar from '../views/admin/components/sidebar/sidebar';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="app-layout-classic w-full h-screen flex overflow-x-hidden">
      <Sidebar isSidebarOpen={isSidebarOpen} />

      <div className={`flex flex-col w-full h-full overflow-hidden ${isSidebarOpen ? "ml-60 md:ml-80" : "ml-0"}`}>
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="flex-1 overflow-auto">
          <ToastContainer />
          <div className={`w-full  px-5 ${isSidebarOpen ? "max-w-screen-xl" : "w-full"}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
