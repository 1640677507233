import { util } from "../../constants/api.constant";
import { http } from "../../constants/http";

let authService = {
  login: async (body) => {
    return await http.post(`${util.baseUrl}auth/login`, body, {
      headers: util.getHeaders(),
    });
  },
  
  deleteUser: async (id) => {
    return await http.put(`${util.baseUrl}user/delete/${id}`, null, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};

export { authService };

