import React, {useState, useEffect} from 'react'
import ProdTable from "./components/Table"
import MainCard from "../../../components/cards/MainCard"
import Button from "../../../components/controls/Button"
import CustomModal from './../../../components/modal/CustomModal';
import { productService } from '../../../services/admin/product.service';
import AddProduct from "./forms/AddProduct";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Product = () => {
    const [showModal, setShowModal] = React.useState(false);  
    const openModal = () =>{
    setShowModal(true);
}
const toggleModal = () =>{
    setShowModal(false);
}
const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState({});


  const fetchProduct = (page) => {
    setLoading(true);
    productService.getAll(page).then((result) => {
      if (result && result?.data) {
        setProduct(result?.data?.data);
        setPagination(result.data?.pagination);
      }
    }).catch((error) => {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchProduct(1); // Fetch the initial page
  }, []);
  return (
    <>
     <div className="w-full h-full">
        <div className="py-6 px-4">
            <h3 className="text-2xl font-bold text-dark">Products</h3>
        </div>
        <MainCard>
        <div className="p-8 flex flex-col"> {/* Use flexbox to control alignment */}
          <div className="flex justify-between items-center mb-4"> {/* Use justify-between for the button */}
            <div />
            
            <div>
              <Button onClick={openModal} value="Add Product" />
            </div>
          </div>
       <div className="">
       <ProdTable
         product={product}
          loading={loading}
           pagination={pagination}
            fetchProduct={fetchProduct}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            />
       </div>
        </div>
      </MainCard>
     
              <CustomModal
                open={showModal}
                onClose={() => toggleModal()}
                component={
                  <AddProduct 
                  fetchProduct={fetchProduct}
                    onClose={() => toggleModal()}
                   
                  />
                }
               
              />
            </div>
          
    </>
  )
}

export default Product