import { util } from "../../constants/api.constant";
import { http } from "../../constants/http";

let orderService = {
  createOrder: async (body) => {
    return await http.post(`${util.baseUrl}order/new`, body,
     {
      headers: {
        "Content-Type": "application/json",
      
      }
    }
      );
    },
  getOrderDetails: async (body) => {
    return await http.post(`${util.baseUrl}checkout/generate-payment`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
export { orderService };
