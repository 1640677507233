import { util } from "../../constants/api.constant";
import { http } from "../../constants/http";
let paymentService = {
  newPayment: async (ref) => {
    return await http.get(`${util.baseUrl}payment/${ref}/new`, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    },
    
  confirmPayment: async (ref) => {
    return await http
      .get(`${util.baseUrl}payment/${ref}/confirm`, {
        headers: {
          "Content-Type": "application/json",
         
        },
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
export { paymentService };
