// import React from 'react'

// import LatestTransactionTable from "./LatestTransactionTable"
import Statistics from "./Statistics"

const DashboardBody = () => {
  return (
    <div>
        <Statistics />
        {/* <LatestTransactionTable /> */}
    </div>
  )
}

export default DashboardBody