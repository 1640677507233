import React, { useState } from "react";
import SubmitButton from "./../../../../components/controls/SubmitButton";
import SelectField from "./../../../../components/controls/SelectField";
import { orderService } from "../../../../services/admin/order.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
const UpdateOrder = ({ orderDetails, onClose, fetchAllOrder }) => {
  // console.log(orderDetails)
  const [tab, setTab] = useState(1);
  const [form, setForm] = useState({
    status: "",
    stage: "",
  });
  const changeTab = (t) => {
    setTab(t);
  };
  const [loading, setLoading] = useState(false);

  const updateStage = () => {
    if (form !== "") {
      setLoading(true);
      const req = {
        stage:form.stage
      }
      
      orderService
        .updateStage(orderDetails.id, req)
        .then((response) => {
          if (response.data && response.data.success) {
            fetchAllOrder(1)
            onClose()
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Please select a status or stage..");
    }
  };
  const updateStatus = () => {
    if (form !== "") {
      setLoading(true);
      const req = {
        status:form.status
      }
    
      orderService
        . updateStatus(orderDetails.id, req)
        .then((response) => {
          if (response.data && response.data.success) {
            fetchAllOrder(1)
            onClose()
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Please select a status or stage..");
    }
  };
  return (
    <div className="p-5">
      <div className="text-center">
        <p className="text-primary font-bold text-xl">Update Order </p>
        <p className="text-dark text-md">
          Update the Order Status and Stage...
        </p>
      </div>
      <div className="flex flex-row space-x-4 items-center justify-center py-4">
        <div
          onClick={() => changeTab(1)}
          className={tab === 1 ? "border-b-4 border-t-3 border-primary" : ""}
        >
          <p className="text-primary text-md">Update Stage</p>
        </div>
        <div
          onClick={() => changeTab(2)}
          className={tab === 2 ? "border-b-4 border-t-3 border-primary" : ""}
        >
          <p className="text-primary text-md">Update Status</p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-2">
        {tab === 1 ? (
          <>
            {" "}
            <div>
              <SelectField
                // label="Stage"
                name="stage"
                onChange={(e) => setForm({ ...form, stage: e.target.value })}
              >
                <option value="">Stage</option>
                <option value="requested">Requested</option>
                <option value="processing">Processing</option>
                <option value="delivered">Delivered</option>
              </SelectField>
            </div>
          </>
        ) : (
          <>
            <div>
              <SelectField
                // label="Status"
                name="status"
                onChange={(e) => setForm({ ...form, status: e.target.value })}
              >
                <option value="">Status</option>
                <option value="pending">Pending</option>
                <option value="successful">Successful</option>
                <option value="refunded">Refunded</option>
              </SelectField>
            </div>
          </>
        )}

        <div className="py-4">
          <SubmitButton
            onClick={!loading ? (tab === 1 ? updateStage : updateStatus) : null}
          >
            {loading ? (
              <ClipLoader color="#fff" size={30} data-testid="loader" />
            ) : (
              "Update"
            )}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};
UpdateOrder.propTypes = {
  onClose: PropTypes.func,
};

export default UpdateOrder;
