// import React from 'react'
import logo from "../../assets/img/logo/logo-light-full.png"
const HeaderLogo = () => {
  return (
    <div className="py-2">
        <img className="w-36 h-12   md:w-48 md:h-16" src={logo} alt="logo"/>
    </div>
  )
}

export default HeaderLogo