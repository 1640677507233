import React from 'react';
import Menu from '@mui/material/Menu';
import PropTypes from "prop-types"
const TableOption = ({ children, icon }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={open ? 'btn p-0 dropdown-toggle hide-arrow show' : 'btn p-0 dropdown-toggle hide-arrow'}
        data-bs-toggle="dropdown"
        // aria-expanded={open}
      >
        {icon ?? <i className="bx bx-dots-vertical-rounded"></i>}
      </button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <div className="bg-gray-100 text-gray-500 dark:text-gray-100 dark:bg-gray-800 my-0 py-0"> {children}</div>
      </Menu>
    </div>
  );
};
TableOption.propTypes={
  children:PropTypes.node,
  icon: PropTypes.elementType,
}
export default TableOption;
