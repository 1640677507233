// import React from 'react'

const Header = () => {
  return (
    <div className="flex flex-col text-start">
        <h3 className="text-3xl font-bold text-primary">eNaira NFC Order in Bulk</h3>
        <p className="text-md py-4">Please make sure to fill in the required fields and submit this form to complete your order.</p>
    </div>
  )
}

export default Header