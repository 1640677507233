// import React from 'react'
import OrderTable from "./components/Table"
import MainCard from "../../../components/cards/MainCard"
const Order = () => {
  return (
    <>
    <div className="py-6 px-4">
        <h3 className="text-2xl font-bold text-dark">All Orders</h3>
    </div>
    <MainCard>
    <div className="p-8 flex flex-col"> {/* Use flexbox to control alignment */}
      
      <OrderTable />
    </div>
  </MainCard>
  {/* {showModal && (
        <div className="fixed inset-0 flex justify-center items-center">
          <CustomModal
            open={showModal}
            onClose={() => toggleModal()}
            component={
              <AddProduct
                // email={email}
                onClose={() => toggleModal()}
               
              />
            }
           
          />
        </div>
       )}  */}
</>
  )
}

export default Order