import { useMemo, useEffect } from "react";
import Pagination from "../../../../components/Pagination/index";
import Table from "../../../../components/Table/index"
import React from "react";
import { useTable, usePagination } from "react-table";
import TableOption from "../../../../components/modal/TableOption";
import { SlOptionsVertical } from "react-icons/sl";
import Spinner from "../../../../components/loader/Spinner";
const IMG_PATH = "/img/";
const ReactTable = ({
  data,
  currentPage,
  pageSize,
  setCurrentPage,
  pagination,
  pageChanged,
}) => {
  const { Tr, Th, Td, THead, TBody } = Table;
  const columns= useMemo(() =>{
      return [
        {
          Header: "Picture",
          accessor: "thumbnailUrl",
          Cell: ({ cell: { value } }) => (
              <div className="py-3 w-20">
                <img className="w-16 h-16" src={value} alt="nfc" />
              </div>
            ),
        },
        {
          Header: "Product Name",
          accessor: "name",
          width: "w-20",
        },
        {
          Header: "Description",
          accessor: "description",
          width: "w-20",
        },
        {
          Header: "Minimum Quantity",
          accessor: "minimumQuantity",
          width: "w-20",
        },
        {
          Header: "Quantity",
          accessor: "quantity",
          width: "w-20",
        },
        {
          Header: "Price",
          accessor: "price",
          Cell: ({ row }) => (
            <div className="w-20 flex flex-row space-x-2">
              <img
                src={`${IMG_PATH}enairalogo.png`}
                alt="Logo"
                className="w-5 h-5"
              />
              <div>{row.original.price || "Price Not Available"}</div>
            </div>
          ),
          width: "w-20",
        },
        {
          Header: "Status",
          accessor: "status",
          width: "w-20",
        },
        {
          Header: "Date",
          accessor: "createdAt",
          width: "w-20",
        },
        {
          Header: "Action",
          accessor: "menu",
          Cell: () => {
            return (
              <div>
                <TableOption
                  index={0}
                  icon={<SlOptionsVertical size={20} className="text-primary" />}
                >
                  <ul className="flex flex-col space-y-2 py-2 px-4 capitalize">
                    <li className="">
                      <button>view</button>
                    </li>
                  </ul>
                </TableOption>
              </div>
            );
          },
          width: "w-20",
        },
      ]
      
  }, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize: setTablePageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage },
      manualPagination: true,
      pageCount: pagination.totalPages,
      pageSize,
    },
    usePagination
  );

  useEffect(() => {
      setCurrentPage(pageIndex)
  }, [setCurrentPage, pageIndex])

  useEffect(() => {
      setTablePageSize(pageSize)
  }, [setTablePageSize, pageSize])
 

return (
  <div>
  <Table {...getTableProps()}>
      <THead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
            ))}
          </Tr>
        ))}
      </THead>
      <TBody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
              ))}
            </Tr>
          );
        })}
      </TBody>
    </Table>
    <div className="flex items-center justify-between py-4">
              <Pagination
                  pageSize={pageSize}
                  currentPage={currentPage}
                  total={pagination?.total ?? 1}
                  onChange={(page) => pageChanged(page)}
              />
          </div>
         
  </div>
);
};
const ProdTable = ({
  product,
  loading,
  pagination,
  fetchProduct,
  currentPage,
  setCurrentPage,
  setPageSize,
  pageSize,
}) => {
  const totalData = product.length



  return (
    <>
      <div className="p-4 flex flex-row justify-between">
        <p className="text-dark font-bold text-xl">All Products</p>
      </div>
      <div className="bg-gray-50 px-5 py-12">
        {loading ? <Spinner /> :  <ReactTable
            data={product}
            dataLength={totalData}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            pagination={pagination}
            pageChanged={(p) => {
              fetchProduct(p);
            }}
            fetchProduct={fetchProduct}
          />}
      </div>
    </>
  );
};

export default ProdTable;
