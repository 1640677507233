// import { useState, useEffect, useCallback } from 'react';
import CheckboxField from "../../../components/controls/CheckboxField";
import Input from "../../../components/controls/Input";
import Spinner from '../../../components/loader/Spinner';
import PropTypes from "prop-types"
import logo from "../../../assets/img/logo/enairalogo.png"

const Product = ({ product, handleCheckboxChange, handleQtyChange, handleCountChange, form, loading }) => {
  return (
    <div className="py-4 flex flex-col">
      <p className="text-xl py-4">NFC</p>
      {loading ? (
        <Spinner />
      ) : (

        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          {product.map((card, index) => (
            <div key={card.id} className="w-full h-full  flex flex-col space-y-3 border rounded-md">
              <div className="w-28 h-28 mx-auto p-3">
                <img src={card.thumbnailUrl} alt={`img-${index}`} />
              </div>
              <div className="flex flex-col space-y-2 p-3 bg-light/25">
                <div className="flex flex-row justify-between">
                  <p className="text-lg font-bold">{card.name}</p>
                  <CheckboxField
                    checked={card.checked}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </div>
                <div className="flex flex-row space-x-1">
                  <img src={logo} className="w-6 h-6" alt="eNaira-logo" />
                  <p className="font-bold text-xl text-primary">{card.price}</p>
                </div>
                <div className='flex flex-col space-y-3  py-4'>
                  <p className='text-md'>{card.description}</p>
                  <p className='text-md'>{card.status}</p>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="">
                    <Input
                      label="Min Quantity"
                      type="text"
                      onChange={(e) => handleQtyChange(index, e)}
                      value={card.minimumQuantity.toString()}
                      placeholder="Min Quantity"
                      disabled={true}
                    />
                  </div>
                  <div className="">
                    <Input
                      label="Count"
                      type="text"
                      onChange={(e) => handleCountChange(index, e)}
                      value={card.count}
                      placeholder="Count"
                      disabled={!card.checked}
                    />
                  </div>
                </div>
                
                <p><span className="text-md font-bold text-primary pr-2">NOTE:</span>Quantity starts from 500 and is multiplied by the selected index</p>
                <div className="flex flex-col space-y-3">
                  <p className="text-md font-bold text-primary">Sub Total for {card.name}</p>
                  <div className="">
                    <div className="flex flex-row space-x-1">
                      <p className="text-md font-bold text-primary">Quantity:</p>
                      <p className="font-semibold text-sm text-dark">
                        {typeof card.totalQuantity === 'number' ? card.totalQuantity.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <div className="flex flex-row space-x-1">
                      <p className="text-md font-bold text-primary">Price:</p>
                      <div className="flex flex-row space-x-1">
                        <img src={logo} className="w-6 h-6" alt="eNaira-logo" />
                        <p className="font-semibold text-sm text-dark">
                          {typeof card.subPrice === 'number' ? card.subPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className='flex flex-row space-x-3 py-6 justify-end'>
        <p className="text-lg font-bold">Total:</p>
        <div className="flex flex-row space-x-1">
          <img src={logo} className="w-6 h-6" alt="eNaira-logo" />
          <p className="font-semibold text-sm text-dark">
            {typeof form.totalPrice === 'number' ? form.totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}
          </p>
        </div>
      </div>
    </div>
  );
}
Product.propTypes = {
  product: PropTypes.array.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleQtyChange: PropTypes.func.isRequired,
  handleCountChange: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
}
export default Product;
