// import React from 'react';
import ReactApexChart from 'react-apexcharts';

const TransactionPieChart = () => {
  // Sample data for the pie chart
  const options = {
    labels: ['Expense', 'Income', 'Savings', "outward", "inward"],
  };

  const series = [30, 40, 20, 10, 5]; // Sample data

  return (
    <div className="w-full max-w-screen-md mx-auto">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        height={350}
      />
    </div>
  );
};

export default TransactionPieChart;
