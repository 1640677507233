import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/authLayout';
import Home from './views/guest/';
import NotFound from "./views/guest/NotFound";
import ProtectedRoute from './utils/routes/ProtectedRoute';
import routes from './utils/routes/routes';
import Login from './views/admin/authentication/login';

function App() {
  // const adminToken = localStorage.getItem('adminToken');
  // const isAuthenticated = !!adminToken; // Check if the token exists

  return (
    <Routes>
      <Route path="/404" element={<NotFound />} />
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<AuthLayout><Login /></AuthLayout>} />
      {/* {isAuthenticated ? (
        <> */}
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute>
                  <route.component />
                </ProtectedRoute>
              }
            />
          ))}
        {/* </>
      ) : (
        <Route path="/login" element={<AuthLayout><Login /></AuthLayout>} />
      )} */}


      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
