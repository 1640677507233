import { util } from "../../constants/api.constant";
import { http } from "../../constants/http";
let orderService = {
  updateStatus: async (id, body) => {
    const token = localStorage.getItem("adminToken")
    return await http.put(`${util.baseUrl}admin/order/${id}/status`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization:`Bearer ${token}`
      }
    });
  },
  updateStage: async (id, body) => {
    const token = localStorage.getItem("adminToken")
    return await http.put(`${util.baseUrl}admin/order/${id}/stage`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization:`Bearer ${token}`
      }
    });
  },
  getAll: async (page) => {
    const token = localStorage.getItem("adminToken")
    return await http.get(`${util.baseUrl}admin/order/all?`, {
    params:{
    page:page,
    size:util.limit,
    },
      headers: {
        "Content-Type": "application/json",
        Authorization:`Bearer ${token}`
      }
    });
  },
  delete: async (id) => {
    return await http.put(`${util.baseUrl}user/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  },
};

export { orderService };

