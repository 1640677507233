import MainCard from "../../../../components/cards/MainCard";
import {FiCreditCard} from "react-icons/fi"
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { BsBoxSeam } from "react-icons/bs"
const DashboardHeader = () =>{

return(
    <>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <MainCard>
            <div className="flex flex-col space-y-4 px-3 py-6">
                <p>Total Products</p>
                <div className="flex flex-row justify-between">
                <p className="text-2xl font-bold">9030</p>
                <BsBoxSeam size={30} className="text-primary" />
                </div>
               
            </div>
        </MainCard>
        <MainCard>
            <div className="flex flex-col space-y-4  px-3 py-6">
                <p>Total Orders</p>
                <div className="flex flex-row justify-between">
                <p className="text-2xl font-bold">53,330.90</p>
                <AiOutlineShoppingCart size={30} className="text-primary" />
                </div>
               
            </div>
        </MainCard>
        <MainCard>
            <div className="flex flex-col space-y-4  px-3 py-6">
                <p>TotalPayment</p>
                <div className="flex flex-row justify-between">
                <p className="text-2xl font-bold">₦ 53,330.90</p>
                <FiCreditCard size={30} className="text-primary" />
                </div>
               
            </div>
        </MainCard>
    </div>
    </>
)
}
export default DashboardHeader