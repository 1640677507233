// import React from 'react'
import logo from "../../assets/img/logo/logo-light-full.png"
const Logo = () => {
  return (
    <div className="py-5">
        <img className="w-48 h-20" src={logo} alt="logo"/>
    </div>
  )
}

export default Logo