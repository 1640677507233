import { useState, useEffect } from 'react';
import Input from "../../../components/controls/Input";
import SelectField from "../../../components/controls/SelectField";
import SubmitButton from '../../../components/controls/SubmitButton';
import statesData from '../../../constants/state.json';
import ClipLoader from "react-spinners/ClipLoader";
import { orderService } from '../../../services/guest/order.service';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types"
const OrderForm = ({ SelectedProducts, setSuccessOrder, setOrderData }) => {
    // console.log(SelectedProducts)

    const [form, setForm] = useState({
        firstname: "",
        lastname: "",
        middlename: "",
        phone: "",
        email: "",
        alias: "",
        state: "",
        lga: "",
        address: "",
        zipCode: "",
        delivery: false,
    })
    const [selectedOption, setSelectedOption] = useState('no'); // State to track the selected option

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        // Update the form.delivery state based on the selected option
        setForm({ ...form, delivery: e.target.value === 'yes' });
    };


    // Define the state variables for selectedState and selectedLGA
    const [selectedState, setSelectedState] = useState('');
    const [selectedLGA, setSelectedLGA] = useState('');

    // Event handler for state selection
    const handleStateChange = (e) => {
        setSelectedState(e.target.value);
        setForm({ ...form, state: e.target.value });
    };

    const handleLGAChange = (e) => {
        setSelectedLGA(e.target.value);
        setForm({ ...form, lga: e.target.value });
    };



    useEffect(() => {
        // You can use statesData here to access the JSON data.
        // Example: statesData.someProperty
    }, [selectedState, selectedLGA]);
   


    const [loading, setLoading] = useState(false)
   
    const createOrder = () => {
        if (form?.firstname !== "" &&
            form?.lastname !== "" &&
            form?.middlename !== "" &&
            form?.phone !== "" &&
            form?.email !== "" &&
            form?.alias !== "" &&
            form?.state !== "" &&
            form?.lga !== "" &&
            form?.address !== "" &&
            form?.zipCode !== "" &&  SelectedProducts !=="") {
            const req = {
                firstname: form.firstname,
                lastname: form.lastname,
                middlename: form.middlename,
                phone: form.phone,
                email: form.email, //optional
                wallet_alias: form.alias,
                state: selectedState,
                lga: selectedLGA,
                address: form.address,
                zipCode: form.zipCode,
                delivery: form.delivery,
                items: SelectedProducts.map((product) => ({
                    productId: product.productId,
                    quantity: product.quantity,
                })),
            }
            // alert(JSON.stringify(req))
            setLoading(true)
            orderService.createOrder(req).then((result) => {
                if (result && result.data.success) {
                    toast.success(result.data.message)
                    setOrderData(result.data.data)
                    setTimeout(() => {
                        // openModal()
                        setSuccessOrder(true)
                    }, 500)
                } else {
                    toast(result.data.message)
                    setSuccessOrder(false)
                }

            }).catch((error) => {
                setSuccessOrder(false)
                setLoading(false)
                toast.error(error.response.data.message)
                console.log(error);
            }).finally(() => {
                setLoading(false)
            });
        } else {
            toast.error("all fields are required!")
        }
    }
    return (
        <>
            <div className="flex flex-col space-y-5 py-12">
                <div className="flex flex-col space-y-3">
                    <p className="text-lg font-bold text-dark">Merchant Information</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <Input label="Wallet Alias/Wallet ID" onChange={(e) =>
                            setForm({ form, alias: e.target.value })
                        } value={form.alias} placeholder="Wallet Alias/Wallet ID" />
                        <div className='md:py-7'>
                            <SubmitButton>
                                Verify Merchant
                            </SubmitButton>

                        </div>
                        <Input
                            type="text"
                            label="First Name"
                            onChange={(e) => setForm({ ...form, firstname: e.target.value })}
                            value={form.firstname}
                            placeholder="First Name"
                        />

                        <Input
                            type="text"
                            label="Middle Name"
                            onChange={(e) => setForm({ ...form, middlename: e.target.value })}
                            value={form.middlename}
                            placeholder="Middle Name"
                        />

                        <Input
                            type="text"
                            label="Last Name"
                            onChange={(e) => setForm({ ...form, lastname: e.target.value })}
                            value={form.lastname}
                            placeholder="Last Name"
                        />

                        <Input
                            type="text"
                            label="Phone Number"
                            onChange={(e) => setForm({ ...form, phone: e.target.value })}
                            value={form.phone}
                            placeholder="Phone Number"
                        />

                        <Input
                            type="email"
                            label="Email Address"
                            onChange={(e) => setForm({ ...form, email: e.target.value })}
                            value={form.email}
                            placeholder="Email Address"
                        />
                    </div>
                </div>
                <div className='flex flex-row space-x-4 py-3'>
                    <p>Do you want it to be deliver to you door step?</p>
                    <div className="flex items-center">
                        <label className="mr-2">Yes</label>
                        <input
                            type="radio"
                            value="yes"
                            checked={selectedOption === 'yes'}
                            onChange={handleOptionChange}
                            className="mr-2"
                        />
                        <label className="mr-2">No</label>
                        <input
                            type="radio"
                            value="no"
                            checked={selectedOption === 'no'}
                            onChange={handleOptionChange}
                            className="mr-2"
                        />
                    </div>
                </div>
                {/* {selectedOption === 'yes' && ( */}
                <div className="flex flex-col space-y-3">
                    <p className="text-lg font-bold text-dark">Delivery Information</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">

                        <SelectField
                            value={selectedState}
                            label="State"
                            onChange={handleStateChange}
                        >
                            <option value="">State</option>
                            {statesData.map((state) => (
                                <option key={state.state} value={state.state}>
                                    {state.state}
                                </option>
                            ))}
                        </SelectField>
                        <SelectField
                            value={selectedLGA}
                            label="LGA"
                            onChange={handleLGAChange}
                        >
                            <option value="">LGA</option>
                            {selectedState &&
                                statesData
                                    .find((state) => state.state === selectedState)
                                    ?.lgas.map((lga) => (
                                        <option key={lga} value={lga}>
                                            {lga}
                                        </option>
                                    ))}
                        </SelectField>
                        <Input
                            type="text"
                            label="Zip/Postal Code"
                            onChange={(e) => setForm({ ...form, zipCode: e.target.value })}
                            value={form.zipCode}
                            placeholder="Zip/Postal Code"
                        />

                        <Input
                            type="text"
                            label="Address"
                            onChange={(e) => setForm({ ...form, address: e.target.value })}
                            value={form.address}
                            placeholder="Address"
                        />

                    </div>
                </div>
                {/* )} */}
                <div>
                    <SubmitButton
                        onClick={!loading ? createOrder : null}>
                        {loading ? (
                            <ClipLoader
                                color="#fff"
                                size={30}
                                data-testid="loader"
                            />
                        ) : (
                            "Make Order"
                        )}
                    </SubmitButton>
                </div>
            </div>
          
        </>
    )
}
OrderForm.propTypes = {
    SelectedProducts: PropTypes.func.isRequired,
}
export default OrderForm