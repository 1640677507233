import { useMemo, useEffect, useState } from "react";
import TableOption from "../../../../components/modal/TableOption";
import { orderService } from "../../../../services/admin/order.service";
import { SlOptionsVertical } from "react-icons/sl";
import Spinner from "../../../../components/loader/Spinner";
import Pagination from "../../../../components/Pagination/index";
import Table from "../../../../components/Table/index"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomModal from ".././../../../components/modal/CustomModal";
import UpdateOrder from "../forms/UpdateOrder";
import React from "react";
import { useTable, usePagination } from "react-table";
// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
const IMG_PATH = "/img/";
const ReactTable = ({
    data,
    fetchAllOrder,
    currentPage,
    pageSize,
    setCurrentPage,
    pagination,
    pageChanged,
  }) => {
    const { Tr, Th, Td, THead, TBody } = Table;
    const [orderDetails, setOrderDetails] = useState({});
    const [showModal, setShowModal] = useState(false);
    const openModal = (id, status, stage) => {
      setOrderDetails({ id: id, status: status, stage: stage });
      setShowModal(true);
    };
    const toggleModal = () => {
      setShowModal(false);
    };
    const columns= useMemo(() =>{
        return [
          {
            Header: "Reference",
            accessor: "reference",
          },
          {
            Header: 'First Name',
            accessor: 'firstname',
            width: "w-20",
          },
          {
            Header: 'Middle Name',
            accessor: 'middlename',
            width: "w-20",
          },
          {
            Header: 'Last Name',
            accessor: 'lastname',
            width: "w-20",
          },
    
          {
            Header: 'Wallet Alias',
            accessor: 'wallet_alias',
            width: "w-20",
          },
          {
            Header: 'Email Address',
            accessor: 'email',
            width: "w-20",
          },
          {
            Header: 'Phone Number',
            accessor: 'phone',
            width: "w-20",
          },
          {
            Header: 'Total Price',
            accessor: 'totalPrice',
            Cell: ({ row }) => (
              <div className="w-20 flex flex-row space-x-2">
                <img
                  src={`${IMG_PATH}enairalogo.png`}
                  alt="Logo"
                  className="w-5 h-5"
                />
                <div>{row.original.totalPrice || "Price Not Available"}</div>
              </div>
            ),
            width: "w-20",
          },
          {
            Header: 'payment Mode',
            accessor: 'paymentMode',
            width: "w-20",
          },
          {
            Header: 'Payment Status',
            accessor: 'paymentStatus',
            Cell: ({row}) => {
              const paymentStatus = row.original.paymentStatus          ;
              let statusText, statusColor, dotColor;
    
              if (paymentStatus === "successful") {
                statusText = "Successful";
                statusColor = "text-green-500";
                dotColor = "bg-green-500";
              } else if (paymentStatus === "pending") {
                statusText = "Pending";
                statusColor = "text-amber-300";
                dotColor = "bg-amber-300";
              } else if (paymentStatus === "paid") {
                statusText = "Paid";
                statusColor = "text-green-500";
                dotColor = "bg-green-500";
              } else if (paymentStatus === "initiated") {
                statusText = "initiated";
                statusColor = "text-amber-300";
                dotColor = "bg-amber-300";
              } else {
                statusText = "Failed";
                statusColor = "text-red-500";
                dotColor = "bg-red-500";
              }
              return (
                <div className="flex items-center w-40">
                  <div className={`h-3 w-3 rounded-full mr-2 ${dotColor}`}></div>
                  <span className={`${statusColor}`}>{statusText}</span>
                </div>
              );
            },
            width: "w-20",
          },
          {
            
            Header: 'Order Status',
            accessor: 'status',
            Cell: ({row}) => {
              const status = row.original.status;
              let statusText, statusColor, dotColor;
    
              if (status === "successfull") {
                statusText = "Successfull";
                statusColor = "text-green-500";
                dotColor = "bg-green-500";
              } else {
                statusText = "Pending";
                statusColor = "text-red-500";
                dotColor = "bg-red-500";
              }
              return (
                <div className="flex items-center w-40">
                  <div className={`h-3 w-3 rounded-full mr-2 ${dotColor}`}></div>
                  <span className={`${statusColor}`}>{statusText}</span>
                </div>
              );
            },
            width: "w-20",
          },
          {
           
            Header: 'Order Stage',
            accessor: 'stage',
            Cell: ({row}) => {
              const stage = row.original.stage;
              let statusText, statusColor, dotColor;
    
              if (stage === "delivered") {
                statusText = "Delivered";
                statusColor = "text-green-500";
                dotColor = "bg-green-500";
              } else if (stage === "Processing") {
                statusText = "processing";
                statusColor = "text-amber-300";
                dotColor = "bg-amber-300";
              } else {
                statusText = "Requested";
                statusColor = "text-red-500";
                dotColor = "bg-red-500";
              }
              return (
                <div className="flex items-center w-40">
                  <div className={`h-3 w-3 rounded-full mr-2 ${dotColor}`}></div>
                  <span className={`${statusColor}`}>{statusText}</span>
                </div>
              );
            },
    
            width: "w-20",
          },
    
          {
            Header: 'Address',
            accessor: 'address',
            width: "w-20",
          },
          {
            Header: 'zip Code',
            accessor: 'zipCode',
            width: "w-20",
          },
          {
            Header: 'LGA',
            accessor: 'lga',
            width: "w-20",
          },
          {
            Header: 'State',
            accessor: 'state',
            width: "w-20",
          },
          {
            Header: 'Date',
            accessor: 'createdAt',
            width: "w-20",
          },
    
          {
            Header: 'Action',
            accessor: 'menu',
            Cell: ({row}) => {
              return (
                <div>
                  <TableOption
                    index={0}
                    icon={<SlOptionsVertical size={20} className="text-primary" />}
                  >
                    <ul className="flex flex-col space-y-2 py-2 px-4 capitalize">
                      <li className="">
                        <button>view</button>
                      </li>
                      <li>
                        <button
                          onClick={() =>
                            openModal(
                              row.original.id,
                              row.original.stage,
                              row.original.status
                            )
                          }
                        >
                          Update Status/Stage
                        </button>
                      </li>
                    </ul>
                  </TableOption>
                </div>
              );
            },
            width: "w-20",
          },
        ]
        
    }, []);
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      setPageSize: setTablePageSize,
      state: { pageIndex },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: currentPage },
        manualPagination: true,
        pageCount: pagination.totalPages,
        pageSize,
      },
      usePagination
    );

    useEffect(() => {
        setCurrentPage(pageIndex)
    }, [setCurrentPage, pageIndex])

    useEffect(() => {
        setTablePageSize(pageSize)
    }, [setTablePageSize, pageSize])
   
  
  return (
    <div>
    <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              ))}
            </Tr>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </Tr>
            );
          })}
        </TBody>
      </Table>
      <div className="flex items-center justify-between py-4">
                <Pagination
                    pageSize={pageSize}
                    currentPage={currentPage}
                    total={pagination?.total ?? 1}
                    onChange={(page) => pageChanged(page)}
                />
            </div>
            <CustomModal
        open={showModal}
        onClose={() => toggleModal()}
        component={
          <UpdateOrder
            fetchAllOrder={fetchAllOrder}
            orderDetails={orderDetails}
            onClose={() => toggleModal()}
          />
        }
      />
    </div>
  );
};


const OrderTable = () => {
    const [order, setOrder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({});
  
    const fetchAllOrder = (page) => {
      setLoading(true);
      orderService
        .getAll(page) // Fetch the current page
        .then((result) => {
          if (result && result?.data) {
            setOrder(result?.data?.data);
            setPagination(result.data?.pagination);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    useEffect(() => {
      fetchAllOrder(1); // Fetch the orders on the current page
    }, []);
  
 

 
  const totalData = order.length
  return (
    <>
      <div className="p-4 flex flex-row justify-between">
        <p className="text-dark font-bold text-xl">All Orders</p>
      </div>
      <div className="bg-gray-50 px-5 py-12">
        {loading ? (
          <Spinner />
        ) : (
            <ReactTable
            data={order}
            dataLength={totalData}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            pagination={pagination}
            pageChanged={(p) => {
              fetchAllOrder(p);
            }}
            fetchAllOrder={fetchAllOrder}
          />
        )}
      </div>
  
      
    </>
  );
};
export default OrderTable;
