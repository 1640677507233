// import React from 'react'

import TransactionChart from "./TransactionChart"
import TransactionPieChart from "./TransactionPieChart"

const Statistics = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between p-4">
        <TransactionChart />
        <TransactionPieChart />
    </div>
  )
}

export default Statistics